import React from "react";
import "./App.css";
import Sidebar from "./Sidebar/Sidebar.js";
import Main from "./Main/Main.js";

function App() {
  
    const [selectedChart, setSelectedChart] = React.useState(null);

    const handleChartButtonClick = (chartName) => {
      // Esta função será chamada quando um botão de gráfico for clicado
      setSelectedChart(chartName);
    }

    return (
        <>  
            <div>
                <Sidebar onChartButtonClick={handleChartButtonClick} />
            </div>
            <div>
                <Main selectedChart={selectedChart} />
            </div>
        </>
    );
}

export default App;