import React from "react";
import "./Main.css";
import { carregarDados } from './FloApi.js';
import { carregarDadosVibe } from "./VibeApi.js";
import { carregarDadosGenie } from "./GenieApi.js";
import { carregarDadosMelon } from "./MelonApi.js";
import Floimage from '../assets/floChart.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const Main = ({ selectedChart }) => {

    const mainContainerRef = React.useRef(null);

    React.useEffect(() => {
        if (mainContainerRef.current) {
            mainContainerRef.current.classList.toggle("main__container__sideOpen");
        }
        if (selectedChart === 'flo') {
            carregarDados();
        } else if (selectedChart === 'vibe') {
            carregarDadosVibe();
        } else if (selectedChart === 'genie') {
            carregarDadosGenie();
        } else if (selectedChart === 'melon') {
            carregarDadosMelon();
        }
    }, [selectedChart]);

    const handleSidebarButtonClick = () => {
        console.log("Botão sidebarButton clicado!");
        if (mainContainerRef.current) {
            mainContainerRef.current.classList.toggle("main__container__sideOpen");
        }
    };

    React.useEffect(() => {
        if (mainContainerRef.current) {
            mainContainerRef.current.classList.toggle("main__container__sideOpen");
            carregarDados();
        }
    }, []);



    return (
        <main ref={mainContainerRef} className="main__container">
            <div className="main__content">
                <button className="sidebarButton" onClick={handleSidebarButtonClick}>
                    <FontAwesomeIcon icon={icon({name: 'bars', family: 'classic', style: 'solid'})} />
                </button>
                <div className="chartMusicsInformation">
                    <div className="chartInformation">
                        <span>
                            <img src={Floimage} id="chartImage" className="chartImage" alt="Chart">
                            </img>
                        </span>
                        <div className="chartInformation__content">
                            <span className="chartText">Texto replace</span>
                            <span className="chartNumberMusic">Total Musica</span>
                        </div>
                    </div>
                    
                    <div id="chartList" className="chartList">
                        <div className="chartOptions">
                            <button className="chartPlayButton">
                                <span>
                                    <FontAwesomeIcon icon={icon({name: 'play', family: 'classic', style: 'solid'})} />
                                </span>
                            </button>
                            <button className="chartHeartButton">
                                <a href="https://www.buymeacoffee.com/h1gor1" target="_blank" rel="noreferrer">
                                    <span>
                                        <FontAwesomeIcon className="iconeCoracao" icon={icon({name: 'heart', family: 'classic', style: 'regular'})} />
                                    </span>
                                </a>
                            </button>
                        </div>
                        <div className="chartInfo">
                            <div className="music__rank__name">
                                <span className="chartListRank">
                                    <FontAwesomeIcon icon={icon({name: 'chart-simple', family: 'classic', style: 'solid'})} />
                                </span>
                            </div>
                            <div className="musicTitleInfo">
                                <span className="chartListTitle">Título</span>
                            </div>
                            <div className="chartListAlbum">
                                <span >Álbum</span>
                            </div>
                            <div className="chartListArtist">
                                <span >Adicionada em</span>
                            </div>
                            
                        </div>
                        <div className="linha"></div>

                        <div class="chartListList">
                            <div class="music-item">
                                <div class="rank">1</div>
                                <div class="music-info">
                                    <img src="caminho/para/imagem.jpg" alt="Imagem do álbum" class="albumImage"></img>
                                    <div class="musics_informations">
                                        <span class="musicName_artistName">
                                            <strong>Nome da Música</strong><br/>
                                            <p class="artistName">Nome do Artista</p>
                                        </span>
                                    </div>
                                    <div class="nameAlbum_date">
                                        <span class="albumName">Nome do Álbum</span>
                                    </div>
                                    <div class="date">
                                        <span class="addedDate">1 de jan. de 2024</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Main;