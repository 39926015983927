import React from "react";
import "./Sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import logo from "../assets/logo.png";

const Sidebar = ({ onChartButtonClick }) => {
    return (
        <nav className="sidebar">
            <div className="sidebar__content">
                <div className="sidebar__navigation">
                    <a href="/" className="sidebarLogoNav">
                        <span className="sidebarLogo">
                            <img src={logo} alt="logo" />
                        </span>
                    </a>
                    <a href="/" className="buttonsNavigation">
                        <span>
                        <FontAwesomeIcon icon={icon({name: 'house', family: 'classic', style: 'solid'})} />
                        </span>
                        <span>Início</span>
                    </a>
                </div>

                <div className="chartChoice__options">
                    <div className="chartChoice">
                        <span>
                            <FontAwesomeIcon icon={icon({name: 'chart-simple', family: 'classic', style: 'solid'})} />
                        </span>
                        <span>Escolha o chart</span>
                    </div>
                        <div className="chartBox">
                            <span className="chartName">Melon</span>
                            <span className="chartDescription">Músicas mais escutadas do Melon em tempo real!</span>
                            <button className="buttonChart" onClick={() => onChartButtonClick('melon')}>Top 100</button>
                        </div>

                        <div className="chartBox">
                            <span className="chartName">Vibe</span>
                            <span className="chartDescription">Músicas mais escutadas do Vibe em tempo real!</span>
                            <button className="buttonChart" onClick={() => onChartButtonClick('vibe')}>Top 100</button>
                        </div>

                        <div className="chartBox">
                            <span className="chartName">Flo</span>
                            <span className="chartDescription">Músicas mais escutadas do Flo em tempo real!</span>
                            <button className="buttonChart" onClick={() => onChartButtonClick('flo')}>Top 100</button>
                        </div>

                        <div className="chartBox">
                            <span className="chartName">Genie</span>
                            <span className="chartDescription">Músicas mais escutadas do Genie em tempo real!</span>
                            <button className="buttonChart" onClick={() => onChartButtonClick('genie')}>Top 25</button>
                        </div>
                </div>
                <div className="sidebar__footer">
                    <div className="about-me">
                        <div className="info">
                            <span>Desenvolvido por:</span>
                            <span className="nameCopy">© Higor Gabriel 2024</span>
                        </div>
                        
                        <div className="social">
                            <a href="https://github.com/H1gor1/" target="_blank" rel="noreferrer">
                                <span>
                                    <FontAwesomeIcon icon={icon({name: 'github', style: 'brands'})} />
                                </span>
                                <span>GitHub</span>
                            </a>

                            <a href="https://www.linkedin.com/in/higor-lino/" target="_blank" rel="noreferrer">
                                <span>
                                    <FontAwesomeIcon icon={icon({name: 'linkedin', style: 'brands'})} />
                                </span>
                                <span>LinkedIn</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Sidebar;