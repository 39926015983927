// FloApi.js
import Floimage from '../assets/floChart.png';

// Função para carregar dados da API e adicionar à lista
async function carregarDados() {
    try {

        const textChartName = document.querySelector(".chartText");
        const textChartNumberMusic = document.querySelector(".chartNumberMusic");
        const chartImage = document.querySelector(".chartImage");
        const main__content = document.querySelector(".main__content");

        textChartName.textContent = "Flo chart";
        chartImage.src = Floimage;
        main__content.style.backgroundImage = "linear-gradient(" +
            "180deg, " +
            "hsl(240deg 87% 85%) 0%, " +
            "hsl(238deg 85% 83%) 9%, " +
            "hsl(237deg 84% 81%) 16%, " +
            "hsl(235deg 83% 79%) 22%, " +
            "hsl(233deg 83% 77%) 27%, " +
            "hsl(232deg 82% 74%) 32%, " +
            "hsl(230deg 82% 72%) 36%, " +
            "hsl(230deg 46% 60%) 41%, " +
            "hsl(231deg 29% 49%) 45%, " +
            "hsl(232deg 26% 37%) 49%, " +
            "hsl(234deg 22% 27%) 53%, " +
            "hsl(236deg 16% 17%) 57%, " +
            "hsl(0deg 0% 7%) 61%, " +
            "hsl(344deg 0% 7%) 64%, " +
            "hsl(344deg 0% 7%) 68%, " +
            "hsl(344deg 0% 7%) 71%, " +
            "hsl(344deg 0% 7%) 75%, " +
            "hsl(344deg 0% 7%) 78%, " +
            "hsl(0deg 0% 7%) 81%, " +
            "hsl(344deg 0% 7%) 85%, " +
            "hsl(344deg 0% 7%) 88%, " +
            "hsl(344deg 0% 7%) 91%, " +
            "hsl(344deg 0% 7%) 94%, " +
            "hsl(344deg 0% 7%) 97%, " +
            "hsl(0deg 0% 7%) 100%)";
        

        // Obter a div onde os itens serão adicionados
        const minhaLista = document.getElementById("chartList");

        // Remover todos os itens existentes
        Array.from(minhaLista.children).forEach(child => {
            if (child.classList.contains("chartListList")) {
                console.log("Removendo item da lista...");
                minhaLista.removeChild(child);
            }
        });

        // Fazer a requisição à API
        const resposta = await fetch("https://api.kcharts.live:3040/api/musicsFlo");
        const dados = await resposta.json();

        // Definir um limite para a quantidade de músicas a serem exibidas
        var limite = 100;
        
        dados.slice(0, limite).forEach(function (musica, index) {
            // Criar a estrutura completa para cada música
            var divListList = document.createElement("div");
            divListList.classList.add("chartListList");

            var divItem = document.createElement("div");
            divItem.classList.add("music-item");

            var divRank = document.createElement("div");
            divRank.classList.add("rank");
            divRank.textContent = index + 1;

            var divInfo = document.createElement("div");
            divInfo.classList.add("music-info");

            var img = document.createElement("img");
            img.src = musica.imagemAlbum;
            img.alt = "Imagem do álbum";
            img.classList.add("albumImage");

            var musicsInformations = document.createElement("div");
            musicsInformations.classList.add("musics_informations");

            var musicNameArtistName = document.createElement("span");
            musicNameArtistName.classList.add("musicName_artistName");
            musicNameArtistName.innerHTML = `
                <strong>${musica.nomeMusica}</strong><br>
                <p class="artistName">${musica.nomeArtista}</p>
            `;

            musicsInformations.appendChild(musicNameArtistName);

            var nameAlbumDate = document.createElement("div");
            nameAlbumDate.classList.add("nameAlbum_date");

            var albumName = document.createElement("span");
            albumName.classList.add("albumName");
            albumName.textContent = musica.nomeAlbum;

            nameAlbumDate.appendChild(albumName);

            var date = document.createElement("div");
            date.classList.add("date");

            // Obter a data atual
            var dataAtual = new Date();

            // Obter o dia do mês
            var dia = dataAtual.getDate();

            // Obter o nome do mês (em português abreviado)
            var meses = [
                "jan.", "fev.", "mar.", "abr.", "mai", "jun.", "jul.", "ago.", "set.", "out.", "nov.", "dez."
            ];
            var mes = meses[dataAtual.getMonth()];

            // Obter o ano
            var ano = dataAtual.getFullYear();

            // Formatando a data no estilo desejado
            var dataFormatada = dia + " de " + mes + " de " + ano;

            date.innerHTML = `<span class="addedDate">${dataFormatada}</span>`;

            divInfo.appendChild(img);
            divInfo.appendChild(musicsInformations);
            divInfo.appendChild(nameAlbumDate);
            divInfo.appendChild(date);

            divItem.appendChild(divRank);
            divItem.appendChild(divInfo);

            divListList.appendChild(divItem);
            minhaLista.appendChild(divListList);
        });
        let numberMusics = dados.length;
        textChartNumberMusic.textContent = `${numberMusics} músicas`;
    } catch (erro) {
        console.error("Erro ao carregar dados da API:", erro);
    }
}

export { carregarDados };