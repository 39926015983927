// Função para carregar dados da API e adicionar à lista
import Melonimage from '../assets/melonChart.png';
async function carregarDadosMelon() {
    try {
        const textChartName = document.querySelector(".chartText");
        const textChartNumberMusic = document.querySelector(".chartNumberMusic");
        const chartImage = document.querySelector(".chartImage");
        const main__content = document.querySelector(".main__content");

        textChartName.textContent = "Melon chart";
        chartImage.src = Melonimage;

        // Defina o estilo de fundo com base nos dados da API
        main__content.style.backgroundImage = "linear-gradient(" +
            "180deg, " +
            "hsl(138deg 100% 40%) 0%, " +
            "hsl(138deg 100% 39%) 1%, " +
            "hsl(138deg 100% 38%) 3%, " +
            "hsl(138deg 99% 37%) 4%, " +
            "hsl(138deg 99% 36%) 6%, " +
            "hsl(138deg 98% 35%) 8%, " +
            "hsl(138deg 98% 34%) 10%, " +
            "hsl(138deg 98% 33%) 12%, " +
            "hsl(138deg 99% 32%) 14%, " +
            "hsl(138deg 99% 30%) 17%, " +
            "hsl(138deg 100% 29%) 20%, " +
            "hsl(138deg 100% 28%) 23%, " +
            "hsl(138deg 100% 27%) 27%, " +
            "hsl(131deg 71% 26%) 31%, " +
            "hsl(127deg 56% 24%) 35%, " +
            "hsl(124deg 44% 20%) 40%, " +
            "hsl(121deg 33% 16%) 45%, " +
            "hsl(117deg 21% 12%) 51%, " +
            "hsl(0deg 0% 7%) 58%, " +
            "hsl(344deg 0% 7%) 65%, " +
            "hsl(344deg 0% 7%) 73%, " +
            "hsl(344deg 0% 7%) 81%, " +
            "hsl(344deg 0% 7%) 88%, " +
            "hsl(344deg 0% 7%) 95%, " +
            "hsl(0deg 0% 7%) 100%" +
            ")";

        // Obter a div onde os itens serão adicionados
        const minhaLista = document.getElementById("chartList");

        // Remover todos os itens existentes
        Array.from(minhaLista.children).forEach(child => {
            if (child.classList.contains("chartListList")) {
                console.log("Removendo item da lista...");
                minhaLista.removeChild(child);
            }
        });

        const resposta = await fetch("https://api.kcharts.live:3000/api/melon/realtime");
        const dados = await resposta.json();

        // Atualize a lista de músicas com os dados da API
        dados.data.forEach(function (musica, index) {
            // Criar a estrutura completa para cada música
            var divListList = document.createElement("div");
            divListList.classList.add("chartListList");

            var divItem = document.createElement("div");
            divItem.classList.add("music-item");

            var divRank = document.createElement("div");
            divRank.classList.add("rank");
            divRank.textContent = musica.rank;

            var divInfo = document.createElement("div");
            divInfo.classList.add("music-info");

            var img = document.createElement("img");
            img.src = musica.imageUrl;
            img.alt = "Imagem do álbum";
            img.classList.add("albumImage");

            var musicsInformations = document.createElement("div");
            musicsInformations.classList.add("musics_informations");

            var musicNameArtistName = document.createElement("span");
            musicNameArtistName.classList.add("musicName_artistName");
            musicNameArtistName.innerHTML = `
                <strong>${musica.title}</strong><br>
                <p class="artistName">${musica.artist}</p>
            `;

            musicsInformations.appendChild(musicNameArtistName);

            var nameAlbumDate = document.createElement("div");
            nameAlbumDate.classList.add("nameAlbum_date");

            var albumName = document.createElement("span");
            albumName.classList.add("albumName");
            albumName.textContent = musica.album;

            nameAlbumDate.appendChild(albumName);

            var date = document.createElement("div");
            date.classList.add("date");

            // Obter a data atual
            var dataAtual = new Date();

            // Obter o dia do mês
            var dia = dataAtual.getDate();

            // Obter o nome do mês (em português abreviado)
            var meses = [
                "jan.", "fev.", "mar.", "abr.", "mai", "jun.", "jul.", "ago.", "set.", "out.", "nov.", "dez."
            ];
            var mes = meses[dataAtual.getMonth()];

            // Obter o ano
            var ano = dataAtual.getFullYear();

            // Formatando a data no estilo desejado
            var dataFormatada = dia + " de " + mes + " de " + ano;

            date.innerHTML = `<span class="addedDate">${dataFormatada}</span>`;

            divInfo.appendChild(img);
            divInfo.appendChild(musicsInformations);
            divInfo.appendChild(nameAlbumDate);
            divInfo.appendChild(date);

            divItem.appendChild(divRank);
            divItem.appendChild(divInfo);

            divListList.appendChild(divItem);
            minhaLista.appendChild(divListList);
        });

        let numberMusics = dados.data.length;
        textChartNumberMusic.textContent = `${numberMusics} músicas`;
    } catch (erro) {
        console.error("Erro ao carregar dados da API:", erro);
    }
}

export { carregarDadosMelon };