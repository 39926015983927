import Vibeimage from '../assets/vibeChart.png';

// Função para carregar dados da API e adicionar à lista
async function carregarDadosVibe() {
    try {
        const textChartName = document.querySelector(".chartText");
        const textChartNumberMusic = document.querySelector(".chartNumberMusic");
        const chartImage = document.querySelector(".chartImage");
        const main__content = document.querySelector(".main__content");

        textChartName.textContent = "Vibe chart";
        chartImage.src = Vibeimage;
        //main__content.removeAttribute("background-image");
        main__content.style.backgroundImage = "linear-gradient(" +
            "180deg, " +
            "hsl(340deg 100% 54%) 0%," +
            "hsl(336deg 95% 50%) 2%," +
            "hsl(333deg 100% 48%) 4%,"+
            "hsl(330deg 100% 46%) 7%,"+
            "hsl(328deg 97% 45%) 9%,"+
            "hsl(326deg 91% 45%) 12%,"+
            "hsl(323deg 83% 45%) 14%,"+
            "hsl(317deg 100% 41%) 17%,"+
            "hsl(311deg 100% 40%) 20%,"+
            "hsl(303deg 100% 38%) 23%,"+
            "hsl(293deg 100% 40%) 27%,"+
            "hsl(282deg 100% 44%) 30%,"+
            "hsl(267deg 93% 54%) 34%,"+
            "hsl(267deg 72% 47%) 38%,"+
            "hsl(267deg 65% 39%) 42%,"+
            "hsl(267deg 58% 31%) 46%,"+
            "hsl(268deg 49% 23%) 51%,"+
            "hsl(270deg 34% 15%) 56%,"+
            "hsl(0deg 0% 7%) 61%,"+
            "hsl(344deg 0% 7%) 67%,"+
            "hsl(344deg 0% 7%) 73%,"+
            "hsl(344deg 0% 7%) 79%,"+
            "hsl(344deg 0% 7%) 86%,"+
            "hsl(344deg 0% 7%) 93%,"+
            "hsl(0deg 0% 7%) 100%" +
            ")";

        // Obter a div onde os itens serão adicionados
        const minhaLista = document.getElementById("chartList");

        // Remover todos os itens existentes
        Array.from(minhaLista.children).forEach(child => {
            if (child.classList.contains("chartListList")) {
                console.log("Removendo item da lista...");
                minhaLista.removeChild(child);
            }
        });

        // Fazer a requisição à API
        const resposta = await fetch("https://api.kcharts.live:3030/api/musicsVibe");
        const dados = await resposta.json();

        // Definir um limite para a quantidade de músicas a serem exibidas
        var limite = 100;

        dados.slice(0, limite).forEach(function (musica, index) {
            // Criar a estrutura completa para cada música
            var divListList = document.createElement("div");
            divListList.classList.add("chartListList");

            var divItem = document.createElement("div");
            divItem.classList.add("music-item");

            var divRank = document.createElement("div");
            divRank.classList.add("rank");
            divRank.textContent = musica.rank;

            var divInfo = document.createElement("div");
            divInfo.classList.add("music-info");

            var img = document.createElement("img");
            img.src = musica.imageUrl;
            img.alt = "Imagem do álbum";
            img.classList.add("albumImage");

            var musicsInformations = document.createElement("div");
            musicsInformations.classList.add("musics_informations");

            var musicNameArtistName = document.createElement("span");
            musicNameArtistName.classList.add("musicName_artistName");
            musicNameArtistName.innerHTML = `
                <strong>${musica.title}</strong><br>
                <p class="artistName">${musica.artist}</p>
            `;

            musicsInformations.appendChild(musicNameArtistName);

            var nameAlbumDate = document.createElement("div");
            nameAlbumDate.classList.add("nameAlbum_date");

            var albumName = document.createElement("span");
            albumName.classList.add("albumName");
            albumName.textContent = musica.album;

            nameAlbumDate.appendChild(albumName);

            var date = document.createElement("div");
            date.classList.add("date");

            // Obter a data atual
            var dataAtual = new Date();

            // Obter o dia do mês
            var dia = dataAtual.getDate();

            // Obter o nome do mês (em português abreviado)
            var meses = [
                "jan.", "fev.", "mar.", "abr.", "mai", "jun.", "jul.", "ago.", "set.", "out.", "nov.", "dez."
            ];
            var mes = meses[dataAtual.getMonth()];

            // Obter o ano
            var ano = dataAtual.getFullYear();

            // Formatando a data no estilo desejado
            var dataFormatada = dia + " de " + mes + " de " + ano;

            date.innerHTML = `<span class="addedDate">${dataFormatada}</span>`;

            divInfo.appendChild(img);
            divInfo.appendChild(musicsInformations);
            divInfo.appendChild(nameAlbumDate);
            divInfo.appendChild(date);

            divItem.appendChild(divRank);
            divItem.appendChild(divInfo);

            divListList.appendChild(divItem);
            minhaLista.appendChild(divListList);
        });
        let numberMusics = dados.length;
        textChartNumberMusic.textContent = `${numberMusics} músicas`;
    } catch (erro) {
        console.error("Erro ao carregar dados da API:", erro);
    }
}

export { carregarDadosVibe };